<style scoped lang="less">
input:disabled {
  background: #f5f7fa;
  color: #c0c4cc;
}
.data-form {
  width: 100%;
}
.data-form-item {
  margin-bottom: 15px;
  margin-left: 10px;
}
.time-stamp {
  min-height: 300px;
}

/deep/ .el-descriptions-item__content {
  display: flex;
  align-items: center;
}
.pwrap {
  display: flex;
  align-items: center;
  .info-address {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}

.top-item-row {
  margin: 10px 25px;
  display: flex;
  justify-content: space-between;
}
.top-item-row-two {
  margin: 10px 25px;
  display: flex;
}
.item-row {
  width: 20%;
  display: inline-block;
}
.item-row-two {
  width: 73.5%;
  display: inline-block;
}
.input-form-item-content {
  width: 180px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-content {
    width: 120px;
  }
}
.input-form-item-textContent {
  width: 400px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-textContent {
    width: 300px;
  }
}
.el-descriptions-row td {
  width: 100px;
}
.pwrap /deep/ .distpicker-address-wrapper select {
  padding: 0;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
}
.timeInput {
  border-radius: 5px;
  padding: 5px 3px 5px 3px;
  p {
    margin: 0;
  }
}
.timeDiv {
  padding: 5px 0 5px 0;
  p {
    margin: 0;
  }
}
input {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #02090c;
  display: inline-block;
  font-size: inherit;
  height: 25px;
  line-height: 25px;
  outline: 0;
  padding: 0 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.item-inline {
  margin: 15px;
  display: flex;
}
.el-input__icon:before {
  content: '';
}
.item-inline > span {
  flex: 0 0 120px;
  height: 25px;
  line-height: 25px;
}
@media screen and (max-width: 1400px) {
  .item-inline > span {
    flex: 0 0 105px;
  }
}
.input-form::-webkit-scrollbar {
  width: 12px;
}
.input-form::-webkit-scrollbar-track {
  border-radius: 10px;
}
.input-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.input-form::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.input-form {
  flex: 1;
  overflow: auto;
  display: flex;
  background: #eff0f7;
  .patientDate {
    height: 28px;
    line-height: 28px;
  }
}
.input-data-left {
  flex-grow: 5;
  flex-shrink: 5;
  padding-right: 10px;
  /deep/ span {
    color: #707070;
  }
  .el-divider__text.is-left {
    font-size: 18px;
  }
  .el-descriptions {
    margin: 10px 25px;
  }
}
.input-data-right {
  flex: 0 1 400px;
  .el-divider__text.is-left {
    font-size: 18px;
  }
}
@media screen and (max-width: 1400px) {
  .input-data-right {
    flex: 0 1 360px;
    height: 92vh;
  }
}
.item-content {
  width: 200px !important;
  .el-date-editor.el-input {
    width: 180px;
  }
}
.el-table {
  border: 1px solid #b4b6ba;
  margin-top: 5px;
}
.input-data-right-middle {
  margin-top: 25px;
}
.input-data-right-bottom {
  margin-top: 30px;
  margin-bottom: 32px;
}
.data-picker {
  height: 25px;
  line-height: 25px;
  width: 180px;
  .el-date-editor.el-input {
    width: 180px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .data-picker {
    width: 120px;
    .el-date-editor.el-input {
      width: 120px;
    }
  }
}
</style>
<template>
  <div
    class="main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div v-show="!isReadOnly" class="crt-content">
      <div v-show="!isReadOnly">
        <el-button
          v-if="isEdit"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-edit"
          @click="editClick"
          :disabled="signed_status"
        >
          编辑
        </el-button>
        <div v-else>
          <el-button
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="closeClick"
            :disabled="signed_status"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            class="common-button commonBtn"
            icon="iconfont icon-yuntongbu"
            @click="synchronousClick"
            :disabled="signed_status"
          >
            同步
          </el-button>
          <el-button
            type="success"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="saveClick"
            :disabled="signed_status"
          >
            保存
          </el-button>
        </div>
      </div>
    </div>

    <div class="input-form">
      <div class="input-data-left">
        <div class="data-form">
          <div v-for="(item, index) in showList" :key="index">
            <el-card
              class="data-form-item"
              v-if="item.group && item.group === 'PATIENT' && item.fields"
            >
              <el-divider content-position="left">病人信息</el-divider>
              <el-descriptions :column="columnNum" :colon="false">
                <el-descriptions-item
                  label="姓名:"
                  label-class-name="my-label"
                  v-if="item.fields['patientName'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.patientsName">
                      {{ patient.patientsName }}
                    </el-tag>
                  </div>
                  <el-input
                    id="patientName"
                    class="input-form-item-content"
                    placeholder="请输入"
                    type="text"
                    v-model="patientInfo.patientsName"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="病案号:"
                  label-class-name="my-label"
                  v-if="item.fields['patientsID'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.patientsID">
                      {{ patient.patientsID }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.patientsID"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="性别:"
                  label-class-name="my-label"
                  v-if="item.fields['patientSex'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.patientsSex">
                      {{ patient.patientsSex }}
                    </el-tag>
                  </div>
                  <cm-select
                    style="margin-top: 5px"
                    class="input-form-item-content"
                    v-model="patientInfo.patientsSex"
                    activeUrl="/v1/webconsole/comboData/get/ssdj_patientsSex"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="年龄:"
                  label-class-name="my-label"
                  v-if="item.fields['patientAge'] === true"
                >
                  <el-tag type="info" v-show="patient.patientsAge || patient.ageDays">
                    <span v-if="patient.ageDays">
                      {{ calculateAgeFromDays(patient.ageDays) }}
                    </span>
                    <span v-else>
                      {{ patient.patientsAge || '-' }}
                    </span>
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item
                  label="文化程度:"
                  label-class-name="my-label"
                  v-if="item.fields['eduLevel'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.eduLevel">{{ patient.eduLevel }}</el-tag>
                  </div>
                  <cm-select
                    style="margin-top: 5px"
                    class="input-form-item-content"
                    v-model="patientInfo.eduLevel"
                    activeUrl="/v1/webconsole/comboData/get/ssjbxx_whcd"
                    v-else
                  ></cm-select>
                </el-descriptions-item>

                <el-descriptions-item
                  label="证件号:"
                  label-class-name="my-label"
                  v-if="item.fields['patientsCertificate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.patientsCertificate">
                      {{ patient.patientsCertificate }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.patientsCertificate"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="本人电话:"
                  label-class-name="my-label"
                  v-if="item.fields['patientsPhone'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.patientsPhone">
                      {{ patient.patientsPhone }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.patientsPhone"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="身高:"
                  label-class-name="my-label"
                  v-if="item.fields['height'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.height">{{ patient.height }}</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.height"
                    size="mini"
                    v-else
                  >
                    <template slot="append">cm</template>
                  </el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="体重:"
                  label-class-name="my-label"
                  v-if="item.fields['weight'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.weight">{{ patient.weight }}</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.weight"
                    size="mini"
                    v-else
                  >
                    <template slot="append">kg</template>
                  </el-input>
                </el-descriptions-item>

                <el-descriptions-item
                  label="婚姻状况:"
                  label-class-name="my-label"
                  v-if="item.fields['marry'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.marry">
                      {{ patient.marry }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.marry"
                    activeUrl="/v1/webconsole/comboData/get/PUB_maritalStatus"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="国家:"
                  label-class-name="my-label"
                  v-if="item.fields['nation'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.nation">{{ patient.nation }}</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    placeholder="请输入国籍"
                    type="text"
                    v-model="patientInfo.nation"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="item.fields['province'] === true && columnNum === 4"
                ></el-descriptions-item>
                <el-descriptions-item
                  :span="2"
                  label="省/市/县"
                  label-class-name="my-label"
                  v-if="item.fields['province'] === true"
                >
                  <div class="pwrap">
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.province">
                        {{ patient.province }}
                        <span v-if="patient.city">/</span>
                        {{ patient.city }}
                        <span v-if="patient.county">/</span>
                        {{ patient.county }}
                      </el-tag>
                    </div>
                    <v-distpicker
                      :province="patientInfo.province"
                      :city="patientInfo.city"
                      :area="patientInfo.county"
                      @selected="onSelected"
                      style="
                        display: flex;
                        padding: 0;
                        height: 28px;
                        font-size: 14px;
                        line-height: 28px;
                      "
                      v-else
                    ></v-distpicker>
                    <div class="info-address">
                      <span>家庭住址：</span>
                      <div v-if="isEdit">
                        <el-tag type="info" v-show="patient.patientsAddress">
                          {{ patient.patientsAddress }}
                        </el-tag>
                      </div>
                      <el-input
                        class="input-form-item-content"
                        type="text"
                        v-model="patientInfo.patientsAddress"
                        size="mini"
                        v-else
                      ></el-input>
                    </div>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="联系人姓名:"
                  label-class-name="my-label"
                  v-if="item.fields['contactName'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.contactName">
                      {{ patient.contactName }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    placeholder="请输入"
                    type="text"
                    v-model="patientInfo.contactName"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="联系人手机号:"
                  label-class-name="my-label"
                  v-if="item.fields['contactPhone'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.contactPhone">
                      {{ patient.contactPhone }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.contactPhone"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="民族:"
                  label-class-name="my-label"
                  v-if="item.fields['ethnicity'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.ethnicity">
                      {{ patient.ethnicity }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    placeholder="请输入民族"
                    type="text"
                    v-model="patientInfo.ethnicity"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="库号:"
                  label-class-name="my-label"
                  v-if="item.fields['fileNumber'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.fileNumber">
                      {{ patient.fileNumber }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.fileNumber"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="住院号:"
                  label-class-name="my-label"
                  v-if="item.fields['hisNo'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.inPatientNo">
                      {{ patient.inPatientNo }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.inPatientNo"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="病区:"
                  label-class-name="my-label"
                  v-if="item.fields['ward'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.ward">{{ patient.ward }}</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.ward"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="床号:"
                  label-class-name="my-label"
                  v-if="item.fields['bedNo'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.bedNo">{{ patient.bedNo }}</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.bedNo"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>

                <el-descriptions-item
                  label="病人性质:"
                  label-class-name="my-label"
                  v-if="item.fields['insuranceType'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.insuranceType">
                      {{ patient.insuranceType }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.insuranceType"
                    activeUrl="/v1/webconsole/comboData/get/PUB_insuranceType"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="患者入院方式:"
                  label-class-name="my-label"
                  v-if="item.fields['admissionType'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.admissionType">
                      {{ patient.admissionType }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.admissionType"
                    activeUrl="/v1/webconsole/comboData/get/ssjbxx_AdmissionType"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="患者入院时间:"
                  label-class-name="my-label"
                  v-if="item.fields['admissionDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.admissionDate">
                      {{ patient.admissionDate }}
                    </el-tag>
                  </div>
                  <div class="data-picker" v-else>
                    <el-date-picker
                      v-model="patientInfo.admissionDate"
                      type="date"
                      align="center"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                    ></el-date-picker>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="患者出院时间:"
                  label-class-name="my-label"
                  v-if="item.fields['dischargeDate'] === true"
                >
                  <div v-if="!isReadOnly && addDischargeDateIsShow">
                    <div class="patientDate">
                      <el-button @click="addDischargeDate" type="text" style="padding: 0">
                        <div v-if="patient.dischargeDate">
                          <el-tag type="info">
                            {{ patient.dischargeDate }}
                          </el-tag>
                        </div>
                        <div v-else>
                          <i class="el-icon-circle-plus-outline" style="color: #409eff !important">
                            添加时间
                          </i>
                        </div>
                      </el-button>
                    </div>
                  </div>
                  <div class="data-picker" v-show="!addDischargeDateIsShow">
                    <el-date-picker
                      ref="inputDate"
                      v-model="patientInfo.dischargeDate"
                      type="date"
                      align="center"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submitDischargeDate()"
                    ></el-date-picker>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="item.fields['dischargeDate'] === true && columnNum === 4"
                ></el-descriptions-item>
                <el-descriptions-item
                  v-if="item.fields['dischargeDate'] === true && columnNum === 4"
                ></el-descriptions-item>
              </el-descriptions>
            </el-card>
            <el-card
              class="data-form-item"
              v-else-if="item.group && item.group === 'BACKGROUND' && item.fields"
            >
              <el-divider content-position="left">背景资料</el-divider>
              <el-descriptions :column="columnNum" :colon="false">
                <el-descriptions-item
                  label="STEMI:"
                  label-class-name="my-label"
                  v-if="item.fields['stemi'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.stemi">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientStemi" style="margin-top: 5px">
                    <el-radio label="是" @change="changeStemi">是</el-radio>
                    <el-radio label="否" @change="changeStemi">否</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  label="是否首次手术:"
                  label-class-name="my-label"
                  v-if="item.fields['isFirstOperation'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.isFirstOperation == '是'">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                  </div>
                  <el-radio-group
                    v-else
                    v-model="patientInfo.isFirstOperation"
                    style="margin-top: 5px"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="
                    item.fields['reOperationReason'] === true &&
                    patientInfo.isFirstOperation &&
                    patientInfo.isFirstOperation === '否'
                  "
                  label="再次手术原因:"
                  label-class-name="my-label"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.reOperationReason">
                      {{ patient.reOperationReason }}
                    </el-tag>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.reOperationReason"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="item.fields['reOperationReason'] === true && columnNum == 4"
                ></el-descriptions-item>
                <el-descriptions-item
                  label="HBS_Ag:"
                  label-class-name="my-label"
                  v-if="item.fields['hbsag'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.hbsag">{{ patient.hbsag }}</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.hbsag" style="margin-top: 5px">
                    <el-radio label="阳性">阳性</el-radio>
                    <el-radio label="阴性">阴性</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  label="梅毒RPR:"
                  label-class-name="my-label"
                  v-if="item.fields['rpr'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.rpr">{{ patient.rpr }}</el-tag>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.rpr" style="margin-top: 5px">
                    <el-radio label="阳性">阳性</el-radio>
                    <el-radio label="阴性">阴性</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  label="抗-HCV:"
                  label-class-name="my-label"
                  v-if="item.fields['hcv'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.hcv">{{ patient.hcv }}</el-tag>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.hcv" style="margin-top: 5px">
                    <el-radio label="阳性">阳性</el-radio>
                    <el-radio label="阴性">阴性</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  label="抗-HIV:"
                  label-class-name="my-label"
                  v-if="item.fields['hiv'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.hiv">{{ patient.hiv }}</el-tag>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.hiv" style="margin-top: 5px">
                    <el-radio label="阳性">阳性</el-radio>
                    <el-radio label="阴性">阴性</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="columnNum == 3 || columnNum == 2"
                ></el-descriptions-item>
                <!-- <el-descriptions-item v-if="columnNum == 3"></el-descriptions-item> -->
                <el-descriptions-item
                  label="既往病史:"
                  label-class-name="my-label"
                  v-if="
                    item.fields['isDyslipidemia'] === true ||
                    item.fields['isHypertension'] === true ||
                    item.fields['isDiabetes'] === true ||
                    item.fields['isHeartDisease'] === true ||
                    item.fields['medicalHistoryNote'] === true
                  "
                >
                  <div v-if="isEdit">
                    <div
                      type="info"
                      v-if="
                        patient.isDyslipidemia ||
                        patient.isHypertension ||
                        patient.isDiabetes ||
                        patient.isHeartDisease ||
                        patient.medicalHistoryNote
                      "
                    >
                      <el-tag
                        style="margin-right: 5px"
                        type="info"
                        v-if="patient.isDyslipidemia === '是'"
                      >
                        血脂异常
                      </el-tag>
                      <el-tag
                        style="margin-right: 5px"
                        type="info"
                        v-if="patient.isHypertension === '是'"
                      >
                        高血压
                      </el-tag>
                      <el-tag
                        style="margin-right: 5px"
                        type="info"
                        v-if="patient.isDiabetes === '是'"
                      >
                        糖尿病
                      </el-tag>
                      <el-tag
                        style="margin-right: 5px"
                        type="info"
                        v-if="patient.isHeartDisease === '是'"
                      >
                        心脏病
                      </el-tag>
                      <template v-if="patient.medicalHistoryNote">
                        <el-tag
                          v-for="(item, index) in patient.medicalHistoryNote?.split('|')"
                          :key="'Note_' + index"
                          style="margin-right: 5px"
                          type="info"
                        >
                          {{ item }}
                        </el-tag>
                      </template>
                    </div>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <div v-else style="display: flex">
                    <el-radio-group v-model="historyNote" style="margin-top: 4px">
                      <el-radio label="无" @change="changeHistoryNote('wu')">无</el-radio>
                      <el-radio label="有" @change="changeHistoryNote('you')">有</el-radio>
                    </el-radio-group>
                    <span
                      v-show="historyNote && historyNote == '有'"
                      style="margin-left: 7px; margin-right: 7px"
                    >
                      (
                    </span>
                    <el-checkbox-group
                      v-show="historyNote && historyNote == '有'"
                      v-model="checkList"
                      @change="handleCheckedChange"
                    >
                      <el-checkbox label="血脂异常">血脂异常</el-checkbox>
                      <el-checkbox label="高血压">高血压</el-checkbox>
                      <el-checkbox label="糖尿病">糖尿病</el-checkbox>
                      <el-checkbox label="心脏病">心脏病</el-checkbox>
                      <el-checkbox label="其他">
                        其他：
                        <el-input
                          class="input-form-item-content"
                          type="text"
                          v-model="patientInfo.medicalHistoryNote"
                          size="mini"
                          :disabled="historyNote_status"
                        ></el-input>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="columnNum" :colon="false">
                <el-descriptions-item
                  label="药物过敏:"
                  label-class-name="my-label"
                  v-if="item.fields['drugAllergyNote'] === true"
                >
                  <div v-if="isEdit">
                    <div v-if="patient.drugAllergyNote">
                      <el-tag
                        v-for="(item, index) in patient.drugAllergyNote?.split('|')"
                        :key="'drug_' + index"
                        style="margin-right: 5px"
                        type="info"
                      >
                        {{ item }}
                      </el-tag>
                    </div>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    placeholder='多个药物过敏使用 "|" 分隔'
                    v-model="patientInfo.drugAllergyNote"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="columnNum" :colon="false">
                <el-descriptions-item
                  label="是否吸烟:"
                  label-class-name="my-label"
                  v-if="item.fields['isSmoke'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.isSmoke == '是'">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.isSmoke" style="margin-top: 5px">
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item
                  label="是否喝酒:"
                  label-class-name="my-label"
                  v-if="item.fields['isAlcohol'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.isAlcohol == '是'">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                  </div>
                  <el-radio-group v-else v-model="patientInfo.isAlcohol" style="margin-top: 5px">
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                :column="columnNum"
                :colon="false"
                v-if="item.fields['importantItem'] === true"
              >
                <el-descriptions-item label="重要事项:" label-class-name="my-label">
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.importantItem">
                      {{ patient.importantItem }}
                    </el-tag>
                    <el-tag type="info" v-else>无</el-tag>
                  </div>
                  <el-input
                    class="input-form-item-textContent"
                    type="textarea"
                    v-model="patientInfo.importantItem"
                    size="mini"
                    :rows="3"
                    placeholder="请输入"
                    v-else
                  ></el-input>
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
            <el-card
              class="data-form-item"
              v-else-if="item.group && item.group === 'OPERATION' && item.fields"
            >
              <el-divider content-position="left">手术信息</el-divider>
              <el-descriptions :column="columnNum" :colon="false">
                <el-descriptions-item
                  label="申请科室:"
                  label-class-name="my-label"
                  v-if="item.fields['sickArea'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.sickArea">{{ patient.sickArea }}</el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select v-model="patientInfo.sickArea" placeholder="请选择">
                      <el-option
                        v-for="item in sickAreaList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="申请时间:"
                  label-class-name="my-label"
                  v-if="item.fields['studyApplyTime'] === true"
                >
                  <el-tag type="info" v-show="patient.studyApplyTime">
                    {{ patient.studyApplyTime }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item
                  label="手术类型:"
                  label-class-name="my-label"
                  v-if="item.fields['examineType'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.examineType">
                      {{ patient.examineType }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.examineType"
                    activeUrl="/v1/webconsole/comboData/get/ssdj_ExamineName"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="拟手术时间:"
                  label-class-name="my-label"
                  v-if="item.fields['planStudyDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.planStudyDate">
                      {{ patient.planStudyDate }}
                    </el-tag>
                  </div>
                  <div class="data-picker" v-else>
                    <date-panel
                      v-model="patientInfo.planStudyDate"
                      type="datetime"
                      align="center"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                    ></date-panel>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="手术时间:"
                  label-class-name="my-label"
                  v-if="item.fields['studyDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.studyDate">
                      {{ patient.studyDate }}
                    </el-tag>
                  </div>
                  <div class="data-picker" v-else>
                    <date-panel
                      v-model="patientInfo.studyDate"
                      type="datetime"
                      align="center"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                    ></date-panel>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="手术开始时间:"
                  label-class-name="my-label"
                  v-if="item.fields['studyStartDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.studyStartDate">
                      {{ patient.studyStartDate }}
                    </el-tag>
                  </div>
                  <div class="data-picker" v-else>
                    <date-panel
                      v-model="patientInfo.studyStartDate"
                      type="datetime"
                      align="center"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submitStudyStartDate()"
                    ></date-panel>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="手术结束时间:"
                  label-class-name="my-label"
                  v-if="item.fields['studyEndDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.studyEndDate">
                      {{ patient.studyEndDate }}
                    </el-tag>
                  </div>
                  <div class="data-picker" v-else>
                    <date-panel
                      v-model="patientInfo.studyEndDate"
                      type="datetime"
                      align="center"
                      value-format="yyyy-MM-dd HH:mm"
                      format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submitStudyEndDate()"
                    ></date-panel>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="术前诊断:"
                  label-class-name="my-label"
                  v-if="item.fields['preoperativeDiagnosis'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.preoperativeDiagnosis">
                      {{ patient.preoperativeDiagnosis }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <cm-select
                      class="input-form-item-content"
                      v-model="patientInfo.preoperativeDiagnosis"
                      activeUrl="/v1/webconsole/comboData/get/AO_preoperative_diagnosis"
                    ></cm-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="临床诊断:"
                  label-class-name="my-label"
                  v-if="item.fields['clinicDiagnose'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag
                      style="margin-right: 5px"
                      type="info"
                      v-for="(item, index) in clinicDiagnoses"
                      :key="'diagnoses_' + index"
                    >
                      {{ item }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="clinicDiagnoseInfo"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      remote
                      reserve-keyword
                      :remote-method="remoteDiagnosis"
                      placeholder="请选择"
                      :loading="surgeryDiagnoseLoading"
                      @change="changeSearchSurgeryDiagnose"
                    >
                      <el-option
                        v-for="(item, index) in pubDiagnosisList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="手术类别:"
                  label-class-name="my-label"
                  v-if="item.fields['classification'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.classification">
                      {{ patient.classification }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.classification"
                    activeUrl="/v1/webconsole/comboData/get/ssjbxx_Classification"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="切口类型:"
                  label-class-name="my-label"
                  v-if="item.fields['cutType'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.cutType">{{ patient.cutType }}</el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.cutType"
                    activeUrl="/v1/webconsole/comboData/get/ssjbxx_Cuttype"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="临床症状:"
                  label-class-name="my-label"
                  v-if="item.fields['clinicalSymptoms'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.clinicalSymptoms">
                      {{ patient.clinicalSymptoms }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.clinicalSymptoms"
                    activeUrl="/v1/webconsole/comboData/get/ssjbxx_ClinicalSymptoms"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="合并症:"
                  label-class-name="my-label"
                  v-if="item.fields['complication'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.complication">
                      {{ patient.complication }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.complication"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="拟施手术:"
                  label-class-name="my-label"
                  v-if="item.fields['examineList'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag
                      style="margin-right: 5px"
                      type="info"
                      v-for="(item, index) in examineNames"
                      :key="'examine_' + index"
                    >
                      {{ item }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="examineNameInfo"
                      multiple
                      filterable
                      default-first-option
                      remote
                      reserve-keyword
                      :remote-method="remoteOperation"
                      placeholder="请选择"
                      :loading="surgeryDiagnoseLoading"
                      @change="changeSearchSurgeryOperation"
                    >
                      <el-option
                        v-for="(item, index) in pubOperationList"
                        :key="index"
                        :label="item.dictName"
                        :value="item.dictName"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="主刀医师:"
                  label-class-name="my-label"
                  v-if="item.fields['examineDoctor'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.examineDoctor">
                      {{ patient.examineDoctor }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.examineDoctorId"
                      placeholder="请选择"
                      @change="changeExamineDoctor"
                    >
                      <el-option
                        v-for="item in doctorList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="一助:"
                  label-class-name="my-label"
                  v-if="item.fields['assistantDoctor1'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patientInfo.assistantDoctor1">
                      {{ patientInfo.assistantDoctor1 }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.assistantDoctor1Id"
                      placeholder="请选择"
                      @change="changeAssistantDoctor1"
                    >
                      <el-option
                        v-for="item in doctorList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="二助:"
                  label-class-name="my-label"
                  v-if="item.fields['assistantDoctor2'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.assistantDoctor2">
                      {{ patient.assistantDoctor2 }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.assistantDoctor2Id"
                      placeholder="请选择"
                      @change="changeAssistantDoctor2"
                    >
                      <el-option
                        v-for="item in doctorList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="三助:"
                  label-class-name="my-label"
                  v-if="item.fields['assistantDoctor3'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.assistantDoctor3">
                      {{ patient.assistantDoctor3 }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.assistantDoctor3Id"
                      placeholder="请选择"
                      @change="changeAssistantDoctor3"
                    >
                      <el-option
                        v-for="item in doctorList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="护士:"
                  label-class-name="my-label"
                  v-if="item.fields['scrubNurse'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.nurses">{{ patient.nurses }}</el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.nurseIds"
                      placeholder="请选择"
                      multiple
                      @change="changeScrubNurse"
                    >
                      <el-option
                        v-for="item in assistantList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="技师:"
                  label-class-name="my-label"
                  v-if="item.fields['tech'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.techs">{{ patient.techs }}</el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.techIds"
                      placeholder="请选择"
                      multiple
                      @change="changeScrubTech"
                    >
                      <el-option
                        v-for="item in technicianList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="巡回:"
                  label-class-name="my-label"
                  v-if="item.fields['tour'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.tour">{{ patient.tour }}</el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-select
                      v-model="patientInfo.tourId"
                      placeholder="请选择"
                      multiple
                      @change="changeTour"
                    >
                      <el-option
                        v-for="item in assistantList"
                        :key="item.userId"
                        :label="item.userName"
                        :value="item.userId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="麻醉会诊:"
                  label-class-name="my-label"
                  v-if="item.fields['anesthesiaConsultation'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.anesthesiaConsultation">
                      {{ patient.anesthesiaConsultation }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.anesthesiaConsultation"
                    size="mini"
                    v-else
                  ></el-input>
                </el-descriptions-item>
                <el-descriptions-item
                  label="麻醉方法:"
                  label-class-name="my-label"
                  v-if="item.fields['anesthesiaMethod'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.anesthesiaMethod">
                      {{ patient.anesthesiaMethod }}
                    </el-tag>
                  </div>
                  <cm-select
                    class="input-form-item-content"
                    v-model="patientInfo.anesthesiaMethod"
                    activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
                    v-else
                  ></cm-select>
                </el-descriptions-item>
                <el-descriptions-item
                  label="麻醉医生:"
                  label-class-name="my-label"
                  v-if="item.fields['anesthesiaDoctor'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.anesthesiaDoctors">
                      {{ patient.anesthesiaDoctors }}
                    </el-tag>
                  </div>
                  <div class="input-form-item-content" v-else>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.anesthesiaDoctors"
                      size="mini"
                    ></el-input>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="记录人:"
                  label-class-name="my-label"
                  v-if="item.fields['recordName'] === true"
                >
                  <el-tag type="info" v-if="patient.recordName">
                    {{ patient.recordName }}
                  </el-tag>
                </el-descriptions-item>
                <el-descriptions-item
                  label="记录时间:"
                  label-class-name="my-label"
                  v-if="item.fields['recordDate'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-if="patient.recordDate">
                      {{ patient.recordDate }}
                    </el-tag>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.recordDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                    ></date-panel>
                  </div>
                </el-descriptions-item>
                <el-descriptions-item
                  label="预计时长:"
                  label-class-name="my-label"
                  v-if="item.fields['planStudyDuration'] === true"
                >
                  <div v-if="isEdit">
                    <el-tag type="info" v-show="patient.planStudyDuration">
                      {{ patient.planStudyDuration }}
                    </el-tag>
                  </div>
                  <el-input
                    class="input-form-item-content"
                    type="text"
                    v-model="patientInfo.planStudyDuration"
                    size="mini"
                    v-else
                  >
                    <template slot="append">分</template>
                  </el-input>
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
          </div>
        </div>
      </div>
      <el-card class="input-data-right" v-if="offSideShow">
        <el-divider content-position="left">手术相关信息</el-divider>
        <div class="input-data-right-bottom">
          <template v-for="(item, index) in showList">
            <div
              :key="index"
              v-if="
                item.group &&
                item.group === 'OFFSIDE' &&
                item.fields &&
                item.fields['relatedInformation'] === true
              "
            >
              <div class="item-inline">
                <span>手术室:</span>
                <el-tag type="info" v-if="patient.lab">{{ patient.lab }}</el-tag>
              </div>
              <div class="item-inline">
                <span>台次:</span>
                <el-tag type="info" v-if="patient.operateNumberTitle">
                  {{ patient.operateNumberTitle }}
                </el-tag>
              </div>
              <div class="item-inline">
                <span>主刀医师:</span>
                <el-tag type="info" v-if="patient.examineDoctor">
                  {{ patient.examineDoctor }}
                </el-tag>
              </div>
              <div class="item-inline">
                <span>申请科室:</span>
                <el-tag type="info" v-show="patient.sickArea">{{ patient.sickArea }}</el-tag>
              </div>
              <div class="item-inline">
                <span>计划手术时间:</span>
                <el-tag type="info" v-show="patient.studyDate">{{ patient.studyDate }}</el-tag>
              </div>
            </div>
          </template>

          <div class="time-stamp" v-if="timeStamp">
            <el-steps direction="vertical" :active="10">
              <el-step title="患者入急症室时间">
                <template slot="description">
                  <div v-if="bonuslist[1].flag">
                    <el-button
                      @click="Bonus(1)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.admissionTime">{{ patient.admissionTime }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.admissionTime"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[1].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="介入医生到达时间">
                <template slot="description">
                  <div v-if="bonuslist[2].flag">
                    <el-button
                      @click="Bonus(2)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.interveneDoctorTime">{{ patient.interveneDoctorTime }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.interveneDoctorTime"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[2].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="介入护士到达时间">
                <template slot="description">
                  <div v-if="bonuslist[3].flag">
                    <el-button
                      @click="Bonus(3)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.interveneNurserTime">{{ patient.interveneNurserTime }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.interveneNurserTime"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[3].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="入导管室时间">
                <template slot="description">
                  <div v-if="bonuslist[4].flag">
                    <el-button
                      @click="Bonus(4)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.enterDate">{{ patient.enterDate }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.enterDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[4].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="麻醉开始时间">
                <template slot="description">
                  <div v-if="bonuslist[5].flag">
                    <el-button
                      @click="Bonus(5)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.anaesthesiaStartDate">
                          {{ patient.anaesthesiaStartDate }}
                        </p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.anaesthesiaStartDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[5].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="手术开始时间">
                <template slot="description">
                  <div v-if="bonuslist[6].flag">
                    <el-button
                      @click="Bonus(6)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.studyStartDate">{{ patient.studyStartDate }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.studyStartDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[6].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>

              <el-step title="手术结束时间">
                <template slot="description">
                  <div v-if="bonuslist[7].flag">
                    <el-button
                      @click="Bonus(7)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.studyEndDate">{{ patient.studyEndDate }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.studyEndDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[7].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="麻醉结束时间">
                <template slot="description">
                  <div v-if="bonuslist[8].flag">
                    <el-button
                      @click="Bonus(8)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.anaesthesiaEndDate">{{ patient.anaesthesiaEndDate }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.anaesthesiaEndDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[8].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="出导管室时间">
                <template slot="description">
                  <div v-if="bonuslist[9].flag">
                    <el-button
                      @click="Bonus(9)"
                      type="text"
                      style="padding: 0"
                      :disabled="isReadOnly"
                    >
                      <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                        <p v-if="patient.leaveDate">{{ patient.leaveDate }}</p>
                        <p v-else-if="!isReadOnly">
                          <i class="el-icon-circle-plus-outline"></i>
                          添加时间
                        </p>
                      </div>
                    </el-button>
                  </div>
                  <div class="item-content" v-else>
                    <date-panel
                      ref="inputsonus"
                      v-model="patientInfo.leaveDate"
                      type="datetime"
                      align="center"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyy-MM-dd HH:mm"
                      @keyup.enter.native="$event.target.blur"
                      @blur="submit(bonuslist[9].id)"
                    ></date-panel>
                  </div>
                </template>
              </el-step>
              <el-step title="报告完成时间">
                <template slot="description">
                  <el-button type="text" style="padding: 0" :disabled="true">
                    <p v-if="patient.reportFinishDate">{{ patient.reportFinishDate }}</p>
                  </el-button>
                </template>
              </el-step>
            </el-steps>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<style>
.my-label {
  min-width: 100px;
  height: 30px;
  line-height: 30px;
}
</style>
<script>
import { mapState } from 'vuex'
import CmSelect from '../../../components/CmSelect.vue'
import VDistpicker from 'v-distpicker'
import deepClone from '../../../plugins/clone'

import DatePanel from '../components/date-picker/index'

export default {
  name: 'StudyInfo',
  components: {
    CmSelect,
    VDistpicker,

    DatePanel
  },
  data() {
    return {
      isEdit: true,
      columnNum: 4,
      signed_status: false,
      loading: false,
      surgeryDiagnoseLoading: false,
      historyNote_status: true,
      addDischargeDateIsShow: true,
      diagnosisUrl: '',
      operationUrl: '',
      examineNames: '',
      clinicDiagnoses: '',
      clinicDiagnoseInfo: '',
      examineNameInfo: '',
      patientStemi: '',
      historyNote: '',
      checkList: [],
      pubOperationList: [],
      pubDiagnosisList: [],
      bonuslist: [
        {
          id: 0,
          bonuslistbai: '',
          stepTitle: '计划手术时间',
          flag: true
        },
        {
          id: 1,
          bonuslistbai: '',
          stepTitle: '患者入急症室时间',
          flag: true
        },
        {
          id: 2,
          bonuslistbai: '',
          stepTitle: '介入医生到达时间',
          flag: true
        },
        {
          id: 3,
          bonuslistbai: '',
          stepTitle: '介入护士到达时间',
          flag: true
        },
        {
          id: 4,
          bonuslistbai: '',
          stepTitle: '入导管室时间',
          flag: true
        },
        {
          id: 5,
          bonuslistbai: '',
          stepTitle: '麻醉开始时间',
          flag: true
        },
        {
          id: 6,
          bonuslistbai: '',
          stepTitle: '手术开始时间',
          flag: true
        },
        {
          id: 7,
          bonuslistbai: '',
          stepTitle: '手术结束时间',
          flag: true
        },
        {
          id: 8,
          bonuslistbai: '',
          stepTitle: '麻醉结束时间',
          flag: true
        },
        {
          id: 9,
          bonuslistbai: '',
          stepTitle: '出导管室时间',
          flag: true
        }
      ],
      patientInfo: {},
      patient: {
        uuid: '', // 报告表ID
        patientsHisNo: '', //检查号
        fileNumber: '', // 库号
        inPatientNo: '', // 医院HIS流水号（住院号）
        patientsName: '', // 患者姓名
        patientsAge: '', // 患者年龄
        patientsSex: '', // 患者性别
        lab: '', // 手术室
        techs: '', // 技师列表
        examineDoctor: '', // 主刀医生
        assistantDoctor1: '', // 一助
        assistantDoctor2: '', // 二助
        assistantDoctor3: '', // 三助
        anesthesiaDoctors: '', // 麻醉医生列表
        nurses: '', // 洗手护士
        tour: '', // 巡回
        examineNames: '', // 手术名称
        examineType: '', // 手术类型
        classification: '', // 手术类别
        clinicalSymptoms: '', // 临床症状
        anesthesiaMethod: '', // 麻醉方式
        anesthesiaConsultation: '', // 麻醉会诊
        stemi: '', // Stemi
        interveneDoctorTime: '', // 介入医生到达时间  yyyy-MM-dd HH:mm:ss
        interveneNurserTime: '', // 介入护士到达时间  yyyy-MM-dd HH:mm:ss
        admissionTime: '', // 患者入急诊室时间 yyyy-MM-dd HH:mm:ss
        admissionType: '', // 入院方式
        enterDate: '', // 入导管室时间 yyyy-MM-dd HH:mm
        leaveDate: '', // 出导管室时间 yyyy-MM-dd HH:mm
        studyApplyTime: '', // 手术申请时间 yyyy-MM-dd HH:mm  (科室申请手术时的日志时间, 这时候填写拟手术日期)
        planStudyDate: '', // 拟手术日期 yyyy-MM-dd HH:mm
        studyDate: '', // 手术日期 yyyy-MM-dd HH:mm
        studyStartDate: '', // 手术开始时间 yyyy-MM-dd HH:mm
        studyEndDate: '', // 手术结束时间 yyyy-MM-dd HH:mm
        anaesthesiaStartDate: '', // 麻醉开始时间
        anaesthesiaEndDate: '', // 麻醉结束时间
        planStudyDuration: '', // 手术预计时长(单位min)
        isFirstOperation: '', // 是否首次手术
        reOperationReason: '', // 再次手术原因
        importantItem: '', // 手术重要事项
        operateNumberTitle: '', // 手术序号(台次)
        hbsag: '', // 乙肝
        hcv: '', // 丙肝
        hiv: '', // 艾滋
        rpr: '', // 梅毒
        bedNo: '', // 床号
        ward: '', // 病区(科室)
        preoperativeDiagnosis: '', // 术前诊断
        clinicDiagnose: '', // 临床诊断
        height: '', // 身高
        weight: '', // 体重
        marry: '', // 婚姻状况
        insuranceType: '', // 医保类型
        admissionCost: '', // 住院总费用
        admissionDate: '', // 住院日期 yyyy-MM-dd HH:mm
        dischargeDate: '', // 出院日期 yyyy-MM-dd HH:mm
        isDyslipidemia: '', // 血脂异常(是 /否)
        isHypertension: '', // 高血压(是/否)
        isDiabetes: '', // 糖尿病(是/否)
        isHeartDisease: '', // 心脏病(是/否)
        medicalHistoryNote: '', // 病史其他描述
        drugAllergyNote: '', // 药物过敏内容
        complication: '', // 合并症
        cutType: '', // 切口类型
        techIds: '', // 技师id列表
        examineDoctorId: '', // 主刀医生id
        assistantDoctor1Id: '', // 一助id
        assistantDoctor2Id: '', // 二助id
        assistantDoctor3Id: '', // 三助id
        tourId: '', // 巡回id
        nurseIds: '', // 护士id
        patientsID: '', // 病案号
        patientsPhone: '', // 患者本人电话
        patientsAddress: '', // 家庭住址
        patientsCertificate: '', // 证件号
        idType: '', // 证件类型
        contactName: '', //患者联系人姓名
        contactPhone: '', //患者联系人手机号
        nation: '', // 国家
        province: '', // 省
        city: '', // 市
        county: '', // 县
        ethnicity: '', // 民族
        eduLevel: '' // 文化程度
      },
      //table
      doctorList: [],
      sickAreaList: [],
      assistantList: [],
      technicianList: [],
      // 是否可编辑
      isReadOnly: false,
      showList: [],
      timeStamp: false,
      offSideShow: false,
      operationId: null
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },

  watch: {
    currentPatient: {
      handler(val) {
        this.renderData(val)
      }
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
    this.get_signed_status()
    this.getActiveUrlData()
    this.windowWidth()
    this.getQueryEnable()
  },
  methods: {
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },
    getQueryEnable() {
      this.$api
        .get(`/v1/webconsole/study/operation/get_with_config/${this.operationId}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.showList = res.data.data.config
            for (let i = 0; i < this.showList.length; i++) {
              const element = this.showList[i]
              if (element.group === 'OFFSIDE') {
                if (element.fields !== null) {
                  this.offSideShow = true
                  this.timeStamp = element.fields['timeStamp']
                }
              }
            }
          }
        })
    },
    remoteDiagnosis(query) {
      if (query !== '') {
        this.surgeryDiagnoseLoading = true
        setTimeout(() => {
          this.surgeryDiagnoseLoading = false
          this.getPubDiagnosis(query)
        }, 200)
      } else {
        this.pubDiagnosisList = []
      }
    },
    remoteOperation(query) {
      if (query !== '') {
        this.surgeryDiagnoseLoading = true
        setTimeout(() => {
          this.surgeryDiagnoseLoading = false
          this.getPubOperationName(query)
        }, 200)
      } else {
        this.pubOperationList = []
      }
    },
    changeSearchSurgeryDiagnose(val) {
      if (val) {
        this.patientInfo.clinicDiagnose = this.clinicDiagnoseInfo.join('|')
      }
    },
    changeSearchSurgeryOperation(val) {
      if (val) {
        let arr = []
        let arrID = []
        let arrList = []
        for (let i = 0; i < val.length; i++) {
          const element = val[i]
          for (let j = 0; j < this.pubOperationList.length; j++) {
            const ele = this.pubOperationList[j]
            if (element === ele.dictName) {
              arr.push(ele.dictName)
              arrID.push(ele.dictIndex)
              arrList.push({
                dictIndex: ele.dictIndex,
                dictName: ele.dictName
              })
            }
          }
        }
        this.patientInfo.examineIds = arrID.join('|')
        this.patientInfo.examineNames = arr.join('|')
        this.patientInfo.examineList = arrList
      } else {
        this.patientInfo.examineIds = ''
        this.patientInfo.examineNames = ''
      }
    },
    // 其他医院用这个，浙二不用
    getPubDiagnosis(query) {
      if (query) {
        this.queryGet = query
        this.diagnosisUrl = `/v1/webconsole/study/diagnosis?keyword=${this.queryGet}`
      } else {
        this.diagnosisUrl = `/v1/webconsole/study/diagnosis`
      }
      this.$api.get(this.diagnosisUrl).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({ label: item.dictName, value: item.dictName })
          })
          this.pubDiagnosisList = arr
        }
      })
    },
    getPubOperationName(query) {
      if (query) {
        this.queryGet = query
        this.operationUrl = `/v1/webconsole/study/puboperationlist?keyword=${this.queryGet}`
      } else {
        this.operationUrl = `/v1/webconsole/study/puboperationlist`
      }
      this.$api.get(this.operationUrl).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({ dictName: item.dictName, dictIndex: item.dictIndex })
          })
          if (this.patientInfo.examineList?.length > 0) {
            let arr1 = [...arr, ...this.patientInfo.examineList]
            for (let i = 0; i < arr1.length; i++) {
              for (let j = i + 1; j < arr1.length; j++) {
                if (arr1[i].dictIndex === arr1[j].dictIndex) {
                  arr1.splice(j, 1)
                  j-- // 保证j的值自加后不变
                }
              }
            }
            this.pubOperationList = arr1
          } else {
            this.pubOperationList = arr
          }
        }
      })
    },
    onSelected(data) {
      this.patientInfo.province = data.province.value
      this.patientInfo.city = data.city.value
      this.patientInfo.county = data.area.value
      this.patientInfo.provinceCode = data.province.code
      this.patientInfo.cityCode = data.city.code
      this.patientInfo.countyCode = data.area.code
    },
    changeStemi(val) {
      if (val == '是') {
        this.patientInfo.stemi = 1
      } else if (val == '否') {
        this.patientInfo.stemi = 0
      }
    },
    changeHistoryNote(val) {
      if (val == 'wu') {
        this.patientInfo.isDyslipidemia = ''
        this.patientInfo.isHypertension = ''
        this.patientInfo.isDiabetes = ''
        this.patientInfo.isHeartDisease = ''
        this.patientInfo.medicalHistoryNote = ''
        this.historyNote_status = true
        this.checkList = []
        this.historyNote = '无'
      } else {
        this.historyNote = '有'
      }
    },
    handleCheckedChange(val) {
      if (val.includes('血脂异常')) {
        this.patientInfo.isDyslipidemia = '是'
      }
      if (val.includes('高血压')) {
        this.patientInfo.isHypertension = '是'
      }
      if (val.includes('糖尿病')) {
        this.patientInfo.isDiabetes = '是'
      }
      if (val.includes('心脏病')) {
        this.patientInfo.isHeartDisease = '是'
      }
      if (val.includes('其他')) {
        this.historyNote_status = false
      } else {
        this.historyNote_status = true
        this.patientInfo.medicalHistoryNote = ''
      }
    },
    //点击文字按钮 文字按钮成为成为输入框 并自动获取焦点
    Bonus(id) {
      this.bonuslist[id].flag = false
      this.$nextTick(() => {
        this.$refs.inputsonus.focus()
      })
    },
    addDischargeDate() {
      if (this.isEdit === false) {
        this.addDischargeDateIsShow = false
        this.$nextTick(() => {
          this.$refs.inputDate[0].focus()
        })
      }
    },
    submitStudyStartDate() {
      if (this.patientInfo.studyStartDate) {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYSTARTDATE'}&date=${this.patientInfo.studyStartDate}`
          )
          .then(
            () => {
              this.patient.studyStartDate = this.patientInfo.studyStartDate

              this.$api
                .get(
                  `/v1/webconsole/study/operation/start/${this.operationId}?date=${this.patientInfo.studyStartDate}`
                )
                .then(() => {
                  return this.$message.success('手术开始')
                })
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
    },
    submitStudyEndDate() {
      if (this.patientInfo.studyEndDate) {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYENDDATE'}&date=${this.patientInfo.studyEndDate}`
          )
          .then(
            () => {
              this.patient.studyEndDate = this.patientInfo.studyEndDate

              this.$api
                .get(
                  `/v1/webconsole/study/operation/stop/${this.operationId}?date=${this.patientInfo.studyEndDate}`
                )
                .then(() => {
                  return this.$message.success('手术结束')
                })
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
    },
    submitDischargeDate() {
      if (this.patientInfo.dischargeDate) {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'DISCHARGEDATE'}&date=${this.patientInfo.dischargeDate}`
          )
          .then(
            () => {
              this.patient.dischargeDate = this.patientInfo.dischargeDate
              this.addDischargeDateIsShow = true
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      } else {
        this.addDischargeDateIsShow = true
      }
    },
    //失去焦点输入框变成文字按钮
    submit(id) {
      this.bonuslist[id].flag = true
      if (this.bonuslist[id].stepTitle == '患者入急症室时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'ADMISSIONTIME'}&date=${this.patientInfo.admissionTime}`
          )
          .then(
            () => {
              this.patient.admissionTime = this.patientInfo.admissionTime
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '介入医生到达时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'INTERVENEDOCTORTIME'}&date=${this.patientInfo.interveneDoctorTime}`
          )
          .then(
            () => {
              this.patient.interveneDoctorTime = this.patientInfo.interveneDoctorTime
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '介入护士到达时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'INTERVENENURSERTIME'}&date=${this.patientInfo.interveneNurserTime}`
          )
          .then(
            () => {
              this.patient.interveneNurserTime = this.patientInfo.interveneNurserTime
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '入导管室时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${this.operationId}?code=${'ENTERDATE'}&date=${
              this.patientInfo.enterDate
            }`
          )
          .then(
            () => {
              this.patient.enterDate = this.patientInfo.enterDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '麻醉开始时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'ANAESTHESIASTARTDATE'}&date=${this.patientInfo.anaesthesiaStartDate}`
          )
          .then(
            () => {
              this.patient.anaesthesiaStartDate = this.patientInfo.anaesthesiaStartDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '手术开始时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYSTARTDATE'}&date=${this.patientInfo.studyStartDate}`
          )
          .then(
            () => {
              this.patient.studyStartDate = this.patientInfo.studyStartDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '手术结束时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYENDDATE'}&date=${this.patientInfo.studyEndDate}`
          )
          .then(
            () => {
              this.patient.studyEndDate = this.patientInfo.studyEndDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '麻醉结束时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'ANAESTHESIAENDDATE'}&date=${this.patientInfo.anaesthesiaEndDate}`
          )
          .then(
            () => {
              this.patient.anaesthesiaEndDate = this.patientInfo.anaesthesiaEndDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      if (this.bonuslist[id].stepTitle == '出导管室时间') {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${this.operationId}?code=${'LEAVEDATE'}&date=${
              this.patientInfo.leaveDate
            }`
          )
          .then(
            () => {
              this.patient.leaveDate = this.patientInfo.leaveDate
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
      setTimeout(() => {
        this.$store.dispatch('setCurrentPatientAct', this.patient.uuid, false)
      }, 1000)
    },
    windowWidth() {
      var w = document.documentElement.clientWidth //获取当前窗口可视操作区域高度
      if (w < 1900 && w > 1410) {
        this.columnNum = 3
      } else if (w < 1410) {
        this.columnNum = 2
      } else {
        this.columnNum = 4
      }
    },
    get_signed_status() {
      this.axios.get(`/v1/webconsole/report/list/${this.$route.query.operationId}`).then((res) => {
        if (res.data && res.data.data) {
          var list = res.data.data
          if (list.length == 0) return
          else {
            list.forEach((e) => {
              if (e.signed == '已签名' && e.templateName == '介入手术护理记录单') {
                this.signed_status = true
              }
            })
          }
        }
      })
    },
    getActiveUrlData() {
      if (this.currentPatient) this.renderData(this.currentPatient)
    },
    renderData(obj) {
      this.patient.patientsID = obj.patientsID
      this.patient.patientsName = obj.patientsName
      this.patient.patientsSex = obj.patientsSex
      this.patient.lab = obj.lab
      this.patient.patientsHisNo = obj.patientsHisNo
      this.patient.studyDate = obj.studyDate == null ? obj.studyDate : obj.studyDate.substr(0, 16)
      this.patient.admissionTime =
        obj.admissionTime == null ? obj.admissionTime : obj.admissionTime.substr(0, 16)
      this.patient.interveneDoctorTime =
        obj.interveneDoctorTime == null
          ? obj.interveneDoctorTime
          : obj.interveneDoctorTime.substr(0, 16)
      this.patient.interveneNurserTime =
        obj.interveneNurserTime == null
          ? obj.interveneNurserTime
          : obj.interveneNurserTime.substr(0, 16)
      this.patient.studyStartDate =
        obj.studyStartDate == null ? obj.studyStartDate : obj.studyStartDate.substr(0, 16)
      this.patient.studyEndDate =
        obj.studyEndDate == null ? obj.studyEndDate : obj.studyEndDate.substr(0, 16)
      this.patient.enterDate = obj.enterDate == null ? obj.enterDate : obj.enterDate.substr(0, 16)
      this.patient.leaveDate = obj.leaveDate == null ? obj.leaveDate : obj.leaveDate.substr(0, 16)
      this.patient = { ...this.patient, ...obj }
      this.patient.idType = '身份证'
      this.examineNames = this.patient.examineNames?.split('|')
      this.clinicDiagnoses = this.patient.clinicDiagnose?.split('|')
      if (this.patient.stemi == 1) {
        this.patientStemi = '是'
      } else if (this.patient.stemi == 0) {
        this.patientStemi = '否'
      }
      if (
        this.patient.isDyslipidemia ||
        this.patient.isHypertension ||
        this.patient.isDiabetes ||
        this.patient.isHeartDisease ||
        this.patient.medicalHistoryNote
      ) {
        this.historyNote = '有'
        if (this.patient.isDyslipidemia == '是') {
          this.checkList.push('血脂异常')
        }
        if (this.patient.isHypertension == '是') {
          this.checkList.push('高血压')
        }
        if (this.patient.isDiabetes == '是') {
          this.checkList.push('糖尿病')
        }
        if (this.patient.isHeartDisease == '是') {
          this.checkList.push('心脏病')
        }
        if (this.patient.medicalHistoryNote) {
          this.checkList.push('其他')
          this.historyNote_status = false
        }
      }
      if (this.patient) this.patientInfo = deepClone(this.patient)
    },
    changeExamineDoctor(val) {
      this.$api.get(`/v1/webconsole/user/usergroups/${val}`).then((res) => {
        if (res.data.data?.length > 0) {
          res.data.data.map((item) => {
            switch (item.dutyCode) {
              case 'assistantDoctor1': {
                this.patientInfo.assistantDoctor1Id = item.userId
                this.changeAssistantDoctor1(item.userId)
                break
              }
              case 'assistantDoctor2': {
                this.patientInfo.assistantDoctor2Id = item.userId
                this.changeAssistantDoctor2(item.userId)
                break
              }
              case 'assistantDoctor3': {
                this.patientInfo.assistantDoctor3Id = item.userId
                this.changeAssistantDoctor3(item.userId)
                break
              }
              case 'nurse': {
                if (this.patientInfo.nurseIds?.length > 0) {
                  let arr1 = [item.userId, ...this.patientInfo.nurseIds]
                  for (let i = 0; i < arr1.length; i++) {
                    for (let j = i + 1; j < arr1.length; j++) {
                      if (arr1[i] === arr1[j]) {
                        arr1.splice(j, 1)
                        j-- // 保证j的值自加后不变
                      }
                    }
                  }
                  this.patientInfo.nurseIds = arr1
                } else {
                  this.patientInfo.nurseIds = []
                  this.patientInfo.nurseIds.push(item.userId)
                }
                this.changeScrubNurse(this.patientInfo.nurseIds)
                break
              }
              case 'techs': {
                if (this.patientInfo.techIds?.length > 0) {
                  let arr1 = [item.userId, ...this.patientInfo.techIds]
                  for (let i = 0; i < arr1.length; i++) {
                    for (let j = i + 1; j < arr1.length; j++) {
                      if (arr1[i] === arr1[j]) {
                        arr1.splice(j, 1)
                        j-- // 保证j的值自加后不变
                      }
                    }
                  }
                  this.patientInfo.techIds = arr1
                } else {
                  this.patientInfo.techIds = []
                  this.patientInfo.techIds.push(item.userId)
                }
                this.changeScrubTech(this.patientInfo.techIds)
                break
              }
              case 'tour': {
                if (this.patientInfo.tourId?.length > 0) {
                  let arr1 = [item.userId, ...this.patientInfo.tourId]
                  for (let i = 0; i < arr1.length; i++) {
                    for (let j = i + 1; j < arr1.length; j++) {
                      if (arr1[i] === arr1[j]) {
                        arr1.splice(j, 1)
                        j-- // 保证j的值自加后不变
                      }
                    }
                  }
                  this.patientInfo.tourId = arr1
                } else {
                  this.patientInfo.tourId = []
                  this.patientInfo.tourId.push(item.userId)
                }
                this.changeTour(this.patientInfo.techIds)
                break
              }
            }
          })
        }
      })
      for (let i = 0; i < this.doctorList.length; i++) {
        const element = this.doctorList[i]
        if (element.userId == val) {
          this.patientInfo.examineDoctor = element.userName
        }
      }
    },
    changeAssistantDoctor1(val) {
      for (let i = 0; i < this.doctorList.length; i++) {
        const element = this.doctorList[i]
        if (element.userId == val) {
          this.patientInfo.assistantDoctor1 = element.userName
        }
      }
    },
    changeAssistantDoctor2(val) {
      for (let i = 0; i < this.doctorList.length; i++) {
        const element = this.doctorList[i]
        if (element.userId == val) {
          this.patientInfo.assistantDoctor2 = element.userName
        }
      }
    },
    changeAssistantDoctor4(val) {
      for (let i = 0; i < this.doctorList.length; i++) {
        const element = this.doctorList[i]
        if (element.userId == val) {
          console.log(element)
          this.patientInfo.recordName = element.userName
        }
      }
    },
    changeAssistantDoctor3(val) {
      for (let i = 0; i < this.doctorList.length; i++) {
        const element = this.doctorList[i]
        if (element.userId == val) {
          this.patientInfo.assistantDoctor3 = element.userName
        }
      }
    },
    changeScrubNurse(val) {
      let string = ''
      for (let j = 0; j < val.length; j++) {
        for (let i = 0; i < this.assistantList.length; i++) {
          const element = this.assistantList[i]
          if (element.userId == val[j]) {
            if (j === val.length - 1) {
              string += element.userName
            } else {
              string += element.userName + ','
            }
          }
        }
      }
      this.patientInfo.nurses = string
    },
    changeScrubTech(val) {
      let string = ''
      for (let j = 0; j < val.length; j++) {
        for (let i = 0; i < this.technicianList.length; i++) {
          const element = this.technicianList[i]
          if (element.userId == val[j]) {
            if (j === val.length - 1) {
              string += element.userName
            } else {
              string += element.userName + ','
            }
          }
        }
      }
      this.patientInfo.techs = string
    },
    changeTour(val) {
      let string = ''
      for (let j = 0; j < val.length; j++) {
        for (let i = 0; i < this.assistantList.length; i++) {
          const element = this.assistantList[i]
          if (element.userId == val[j]) {
            if (j === val.length - 1) {
              string += element.userName
            } else {
              string += element.userName + ','
            }
          }
        }
      }
      this.patientInfo.tour = string
    },
    getSickAreaListList() {
      this.$api.get(`/v1/webconsole/user/depts`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          let arr = []
          res.data.data.map((item) => {
            arr.push({
              name: item.name,
              id: item.id
            })
          })
          this.sickAreaList = arr
        }
      })
    },
    getDoctorList() {
      this.$api.get(`/v1/webconsole/study/doctors`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.doctorList = arr
        }
      })
    },
    getAssistant() {
      this.$api.get(`/v1/webconsole/study/nurses`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.assistantList = arr
        }
      })
    },
    getTechnician() {
      this.$api.get(`/v1/webconsole/study/techs`).then((res) => {
        let arr = []
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            arr.push({
              userName: item.userName,
              userId: item.userID
            })
          })
          this.technicianList = arr
        }
      })
    },
    synchronousClick() {
      if (this.patientInfo.patientsID) {
        this.$api
          .get(
            `/v1/webconsole/patientCenter/patient/his/${this.patientInfo.patientsID}/${this.patientInfo.uuid}`
          )
          .then((res) => {
            if (res.data.status === 200 && res.data.data !== null) {
              this.patientInfo.patientsName =
                this.patientInfo.patientsName || res.data.data.patientsName
              this.patientInfo.idType = res.data.data.idType || this.patientInfo.idType
              this.patientInfo.eduLevel = res.data.data.eduLevel || this.patientInfo.eduLevel
              this.patientInfo.fileNumber = res.data.data.fileNumber || this.patientInfo.fileNumber
              this.patientInfo.patientsCertificate =
                res.data.data.patientsCertificate || this.patientInfo.patientsCertificate
              this.patientInfo.ethnicity = res.data.data.ethnicity || this.patientInfo.ethnicity
              this.patientInfo.nation = res.data.data.nation || this.patientInfo.nation
              this.patientInfo.province = res.data.data.province || this.patientInfo.province
              this.patientInfo.city = res.data.data.city || this.patientInfo.city
              this.patientInfo.county = res.data.data.county || this.patientInfo.county
              this.patientInfo.patientsAddress =
                res.data.data.patientsAddress || this.patientInfo.patientsAddress
              this.patientInfo.patientsPhone =
                res.data.data.patientsPhone || this.patientInfo.patientsPhone
              this.patientInfo.patientsDOB =
                res.data.data.patientsDOB || this.patientInfo.patientsDOB
              this.patientInfo.patientsAge =
                Math.floor(res.data.data.ageDays / 365) ||
                res.data.data.patientsAge ||
                this.patientInfo.patientsAge
              this.patientInfo.patientsSex =
                res.data.data.patientsSex || this.patientInfo.patientsSex
              this.patientInfo.height = res.data.data.patientsHeight || this.patientInfo.height
              this.patientInfo.weight = res.data.data.patientsWeight || this.patientInfo.weight
              this.patientInfo.ward = res.data.data.patientsWard || this.patientInfo.ward
              this.patientInfo.bedNo = res.data.data.patientsBedNo || this.patientInfo.bedNo
              this.patientInfo.admissionDate =
                res.data.data.admissionDate || this.patientInfo.admissionDate
              this.patientInfo.dischargeDate =
                res.data.data.dischargeDate || this.patientInfo.dischargeDate
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        this.$message.error('请输入患者病案号')
      }
    },
    editClick() {
      this.isEdit = false
      this.getDoctorList()
      this.getSickAreaListList()
      this.getAssistant()
      this.getTechnician()
      this.getPubDiagnosis()
      this.getPubOperationName()
      this.clinicDiagnoseInfo = deepClone(this.patientInfo.clinicDiagnose?.split('|'))
      this.patientInfo.nurseIds = this.patientInfo.nurseIds?.split(',') || []
      this.patientInfo.techIds = this.patientInfo.techIds?.split(',') || []
      this.patientInfo.tourId = this.patientInfo.tourId?.split(',') || []
      this.examineNameInfo = deepClone(this.patientInfo.examineNames?.split('|'))
      if (
        !this.patient.isDyslipidemia &&
        !this.patient.isHypertension &&
        !this.patient.isDiabetes &&
        !this.patient.isHeartDisease &&
        !this.patient.medicalHistoryNote
      ) {
        this.historyNote = '无'
      } else this.historyNote = '有'
    },
    closeClick() {
      this.isEdit = true
      if (this.patient) {
        this.patientInfo = deepClone(this.patient)
      }
    },
    Save() {
      this.isEdit = true
      delete this.patientInfo.examineNameList
      delete this.patientInfo.doctorIdList
      delete this.patientInfo.doctorNameList
      delete this.patientInfo.nurseIdList
      delete this.patientInfo.nurseNameList
      delete this.patientInfo.tourIdList
      delete this.patientInfo.tourNameList
      delete this.patientInfo.techIdList
      delete this.patientInfo.techNameList
      this.$api
        .post(`/v1/webconsole/study/operation/save/${this.operationId}`, {
          ...this.patientInfo
        })
        .then(
          (res) => {
            this.loading = false
            this.patient = res.data.data
            this.$store.dispatch('setCurrentPatientAct', this.patient.uuid, false)
            this.examineNames = this.patient.examineNames?.split('|')
            this.clinicDiagnoses = this.patient.clinicDiagnose?.split('|')
            // return this.$message.success('编辑成功')
            return this.$MessageAi.Message({
              type: 'success',
              icon: 'el-icon-success',
              message: '编辑成功'
            })
          },
          () => {
            this.loading = false
            return this.$message.error('保存出错')
          }
        )
    },
    saveClick() {
      if (
        !document.getElementById('patientName').value ||
        !this.patientInfo.patientsSex ||
        !this.patientInfo.examineDoctor ||
        (!this.patientInfo.planStudyDate &&
          !this.patientInfo.studyDate &&
          !this.patientInfo.studyStartDate &&
          !this.patientInfo.studyEndDate) ||
        !this.patientInfo.examineNames
      ) {
        // 手术类型、!this.patientInfo.examineType || 后端要求去掉20240130
        this.$message({
          message: '请完整输入患者姓名、性别、拟施手术、拟手术时间或手术时间、主刀医生',
          type: 'warning'
        })
      } else {
        this.$confirm('是否保存编辑信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.loading = true

            if (this.patientInfo.techs) {
              let arr = []
              arr.push(this.patientInfo.techs)
              this.patientInfo.techs = arr.join(',')
            }

            this.patientInfo.techIds = this.patientInfo.techIds?.join(',')

            this.patientInfo.nurseIds = this.patientInfo.nurseIds?.join(',')
            this.patientInfo.tourId = this.patientInfo.tourId?.join(',')

            if (this.patientInfo.nurses) {
              this.patientInfo.nurse = this.patientInfo.nurses.split(',').join('|')
            }
            if (this.patientInfo.tour) {
              this.patientInfo.nurse = this.patientInfo.tour.split(',').join('|')
            }
            if (this.patientInfo.tour && this.patientInfo.nurses) {
              this.patientInfo.nurse =
                this.patientInfo.nurses.split(',').join('|') +
                '|' +
                this.patientInfo.tour.split(',').join('|')
            }

            this.Save()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      }
    }
  }
}
</script>
